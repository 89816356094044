import React from "react";

import { ClientParams, SignInForm, SignInFormProps } from "@envato/sso-common";

import TwoColumn from "../../../layouts/TwoColumn";

import ContextualSidePanel from "./ContextualSidePanel";
import { Card } from "../../../components/MainContent";
export interface ViewProps extends SignInFormProps {
  params: ClientParams;
  signUpLink: string;
}

const View: React.FC<ViewProps> = (props) => {
  const { signUpLink, params } = props;

  return (
    <TwoColumn
      size="large"
      showSignUp={true}
      sidePanel={
        <ContextualSidePanel params={params} signUpLink={signUpLink} />
      }
    >
      <Card>
        <SignInForm {...props} />
      </Card>
    </TwoColumn>
  );
};

export default View;
